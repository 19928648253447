export enum AppStep {
  BrowseImage,
  ResultsPage,
  SimilarInMeasurePage,
}

export enum ErrorType {
  FileSize = 'FileSize',
  FileFormat = 'FileFormat',
  GenericError = 'GenericError',
  NetworkError = 'NetworkError',
  ImagesNotFound = 'ImagesNotFound',
  SimilarInMeasureError = 'SimilarInMeasureError',
}

export enum OrderType {
  Similar = 'similar',
  LowestPrice = 'lowest',
  BiggestPrice = 'biggest',
}

export enum DepartmentType {
  Feminine = 'feminine',
  Masculine = 'masculine',
  Kids = 'kids',
}

export enum PriceType {
  PriceTier1 = 'price_tier_1',
  PriceTier2 = 'price_tier_2',
  PriceTier3 = 'price_tier_3',
  PriceTier4 = 'price_tier_4',
  PriceTier5 = 'price_tier_5',
}

export enum SourceType {
  Similar = 'similar',
  Catalog = 'catalog',
  Normal = 'normal',
}
