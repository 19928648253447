import { tenantIdFromQuery } from '../common/consts';

export const setStorageSearchesCounter = (value: number) => {
  window.localStorage.setItem(
    `SEARCHES_COUNTER__${tenantIdFromQuery}`,
    JSON.stringify(value)
  );
};

export const getStorageSearchesCounter = () => {
  const searches = window.localStorage.getItem(
    `SEARCHES_COUNTER__${tenantIdFromQuery}`
  );
  if (searches !== null) return JSON.parse(searches);
  return searches;
};

export const incrementSearchesCounter = () => {
  const searches = getStorageSearchesCounter();
  if (searches !== null) return setStorageSearchesCounter(searches + 1);
};

const ensureSearchCountIsSet = () => {
  const searches = getStorageSearchesCounter();
  if (searches === null) return setStorageSearchesCounter(0);
};

export const loadLocalStorageItems = () => {
  ensureSearchCountIsSet();
};
