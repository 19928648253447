import { analytics } from './common/analytics';
import { initialImage, initialSize } from './common/consts';
import { SourceType } from './common/enums';
import { searchImage } from './state/actions';
import { setSource } from './state/source-actions';

export const getUrlExt = (url?: string) => {
  const e = url?.split(/[#?]/)?.[0]?.split('.')?.pop()?.trim();
  if (e === 'jpg') return 'jpeg';
  const validExt = ['gif', 'jpeg', 'webp'].includes(e || '');
  return validExt ? e : 'jpeg';
};

export const loadInitialImage = async () => {
  if (!initialImage) return;

  const ext = getUrlExt(initialImage);
  if (!ext) return;

  console.debug('TW :: Loading initial image from query param');

  setSource(SourceType.Similar);

  searchImage(undefined, initialImage, {
    loadAsUrl: true,
    forceMimeType: `image/${ext}`,
    initialSize: initialSize ? initialSize : undefined,
  });

  if (initialSize) {
    return analytics.track('Similar In Measure Loaded', {
      image_url: initialImage,
    });
  }

  analytics.track('Initial Image Loaded', {
    image_url: initialImage,
  });
};
