const KEYS_TO_BLOCK: Record<number, number> = { 37: 1, 38: 1, 39: 1, 40: 1 };

function __preventDefault(e: { preventDefault: () => void }) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e: {
  keyCode: number;
  preventDefault: () => void;
}) {
  if (KEYS_TO_BLOCK[e.keyCode]) {
    __preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener(
    'test',
    null as any,
    Object.defineProperty({}, 'passive', {
      // eslint-disable-next-line getter-return
      get: function () {
        supportsPassive = true;
      },
    })
  );
} catch (e) {}

const wheelOpt = supportsPassive ? { passive: false } : false;
const wheelEvent =
  'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// Polyfill scroll methods
window.addEventListener('DOMMouseScroll', __preventDefault, false); // older FF
window.addEventListener(wheelEvent, __preventDefault, wheelOpt); // modern desktop
window.addEventListener('touchmove', __preventDefault, wheelOpt); // mobile
window.addEventListener('keydown', preventDefaultForScrollKeys, false);

export function stopScrollBlockerPropagation(element: HTMLElement) {
  const __stopPropagation = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
  };
  element.addEventListener('DOMMouseScroll', __stopPropagation, false); // older FF
  element.addEventListener(wheelEvent, __stopPropagation, wheelOpt); // modern desktop
  element.addEventListener('touchmove', __stopPropagation, wheelOpt); // mobile
  element.addEventListener('keydown', __stopPropagation, false);
}
