import { analytics } from './common/analytics';

const HEART_BEAT_INTERVAL = 1000 * 10; // 10 seconds

let intervalId: number | undefined;

const sendHeartBeat = () => {
  // send heart beat event with "length" of 10 seconds
  analytics.track('Heart Beat', { l: 10 });
};

document.addEventListener('visibilitychange', (e) => {
  if (document.visibilityState === 'visible') {
    initHeartBeat();
  } else {
    clearInterval(intervalId);
  }
});

export const initHeartBeat = () => {
  if (intervalId) {
    clearInterval(intervalId);
  }
  intervalId = window.setInterval(sendHeartBeat, HEART_BEAT_INTERVAL);
};
