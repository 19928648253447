import { entity } from 'simpler-state';
import {
  AppStep,
  DepartmentType,
  OrderType,
  PriceType,
  SourceType,
} from '../common/enums';
import {
  Error,
  PriceTier,
  SearchResult,
  SearchResultItem,
} from '../common/types';
// import { searchResultMock, selectImageMock } from "../__tests__/search.mock";

interface SessionStats {
  startedAt: Date;
  endedAt?: Date;
  firstConversionAt?: Date;
}

export interface State {
  tenantIsEnabled?: boolean;
  enableSimilarInMeasure?: boolean;
  appStep: AppStep;
  currentImage?: string;
  initialSize?: string;
  currentFileImage?: File;
  searchingImage: boolean;
  searchingResults: boolean;
  convertingImage: boolean;
  cropEnabled: boolean;
  cropPreview?: string;
  croppedImageFile?: File;
  results: SearchResult[];
  selectedResult?: SearchResult;
  error?: Error;
  session: SessionStats;
  catalogItems?: string[];
  isEmbeddedApp?: boolean;
  imageLogo?: string;
  isImageFromCatalog?: boolean;
  featureFlags?: Record<string, boolean>;
  customLabels?: Record<string, string>;
  customColors?: Record<string, string>;
  customFonts?: Record<string, string>;
  customTexts?: Record<string, string>;
  extraCustomization?: Record<string, string>;
  loadingFeatureFlags: boolean;
  selectedOrder: {
    type: OrderType;
    name: string;
  };
  selectedDepartment: {
    type: DepartmentType | undefined;
    name: string;
  };
  selectedPriceTiers?: {
    type: PriceType | undefined;
    name: string;
  }[];
  orderFunction?: (a: SearchResultItem, b: SearchResultItem) => number;
  priceTiers?: PriceTier[];
  searchSource?: SourceType;
  showFeedback: boolean;
}

export const emptyState = {
  appStep: AppStep.BrowseImage,
  searchingImage: false,
  searchingResults: false,
  convertingImage: false,
  cropEnabled: false,
  results: [],
  session: {
    startedAt: new Date(),
  },
  loadingFeatureFlags: false,
  selectedOrder: {
    type: OrderType.Similar,
    name: 'Similaridade',
  },
  selectedDepartment: {
    type: undefined,
    name: '',
  },
  selectedPriceTier: null,
  orderFunction: () => 0,
  searchSource: SourceType.Normal,
  showFeedback: false,
};

// const fakeState = {
//   appStep: AppStep.ResultsPage,
//   searchingImage: false,
//   currentImage: selectImageMock,
//   results: searchResultMock as SearchResult[],
//   selectedResult: searchResultMock?.[0] as SearchResult,
// };

export const state = entity<State>(emptyState);

export const getConvertedAtFromState = () => {
  // we need to cast as any so TS won't complain about it
  const startedAt = state.get().session.startedAt as any;
  // converts to seconds and ceil to the nearest integer
  return Math.ceil(Math.abs((new Date() as any) - startedAt) / 1000);
};
