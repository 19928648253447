import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { closeIframeMessage } from '../../common/helpers/iframe';
import { stopScrollBlockerPropagation } from '../../common/misc';

const OpaqueLayout: FC<PropsWithChildren> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    stopScrollBlockerPropagation(ref.current);
  }, [ref]);

  return (
    <div
      ref={ref}
      className={`fixed top-0 right-0 left-0 bottom-0 flex w-full md:inset-0 h-modal md:h-full items-center justify-center overflow-x-hidden overflow-y-auto z-50 bg-black/40`}
      onClick={(e) => {
        if (e.target !== e.currentTarget) return;
        closeIframeMessage();
      }}
    >
      {children}
    </div>
  );
};

export default OpaqueLayout;
