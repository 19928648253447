import { state } from '.';
import { tenantIdFromQuery } from '../common/consts';

const featuresQuery = `query GetFeatures($tenant_id: uuid!) @cached {
  tenants_customization_by_pk(tenant_id: $tenant_id) {
    flags
    catalog
    image_logo
    price_tiers 
    custom_labels
    custom_colors
    custom_fonts
    custom_texts
    extra_customization
    is_embedded_app
  }
}`;

export const loadFeatureFlags = async () => {
  state.set((currentState) => ({
    ...currentState,
    loadingFeatureFlags: true,
  }));

  const features = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT!, {
    method: 'POST',
    body: JSON.stringify({
      query: featuresQuery,
      variables: {
        tenant_id: tenantIdFromQuery,
      },
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const featuresJson = await features.json();

  const flags = featuresJson?.data?.tenants_customization_by_pk?.flags;
  const catalog = featuresJson?.data?.tenants_customization_by_pk?.catalog;
  const imageLogo = featuresJson?.data?.tenants_customization_by_pk?.image_logo;
  const priceTiers =
    featuresJson?.data?.tenants_customization_by_pk?.price_tiers;
  const customLabels =
    featuresJson?.data?.tenants_customization_by_pk?.custom_labels;
  const customColors =
    featuresJson?.data?.tenants_customization_by_pk?.custom_colors;
  const customFonts =
    featuresJson?.data?.tenants_customization_by_pk?.custom_fonts;
  const customTexts =
    featuresJson?.data?.tenants_customization_by_pk?.custom_texts;
  const extraCustomization =
    featuresJson?.data?.tenants_customization_by_pk?.extra_customization;
  const isEmbeddedApp =
    featuresJson?.data?.tenants_customization_by_pk?.is_embedded_app;

  state.set((currentState) => ({
    ...currentState,
    catalogItems: catalog ?? [],
    imageLogo: imageLogo,
    featureFlags: {
      showCatalog: flags?.show_catalog ?? false,
      departmentFilter: flags?.department_filter ?? false,
      priceFilter: flags?.price_filter ?? false,
    },
    customLabels: {
      departmentLabel: customLabels?.department_label,
    },
    customColors: {
      backgroundColor: customColors?.background_color,
      highlightColor: customColors?.highlight_color,
      borderColor: customColors?.border_color,
    },
    customFonts: {
      fontFamily: customFonts?.font_family,
    },
    customTexts: {
      browseTitle: customTexts?.browse_title,
      browseSubtitle: customTexts?.browse_subtitle,
      mobileBrowseSubtitle: customTexts?.mobile_browse_subtitle,
      resultTitle: customTexts?.result_title,
      resultSubtitle: customTexts?.result_subtitle,
    },
    loadingFeatureFlags: false,
    priceTiers,
    extraCustomization: {
      enableLowercase: extraCustomization?.enable_lowercase,
      centralizeText: extraCustomization?.centralize_text,
      uploadButtonLabel: extraCustomization?.upload_button_label,
      secondaryUploadButtonLabel: extraCustomization?.secondary_upload_button_label,
      uploadButtonPersonalBorder:
        extraCustomization?.upload_button_personal_border,
      firstWordInBold: extraCustomization?.first_word_in_bold,
      disableWidestText: extraCustomization?.disable_widest_text,
    },
    isEmbeddedApp: isEmbeddedApp,
  }));
};
