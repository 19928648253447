import { useState, useEffect, useMemo } from 'react';

export const isMobile = window.innerWidth < 768;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

let timeoutId: any = undefined;

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setWindowDimensions(getWindowDimensions());
      }, 300);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeoutId);
    };
  }, []);

  const isMobile = useMemo(() => {
    return windowDimensions.width < 768;
  }, [windowDimensions]);

  return { windowDimensions, isMobile };
}
