import { state } from '.';
import { SourceType } from '../common/enums';

export const setSource = (source: SourceType) => {
  state.set((currentState) => {
    return {
      ...currentState,
      searchSource: source,
    };
  });
};
