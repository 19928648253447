import { state } from '.';
import { analytics } from '../common/analytics';

export const setShowFeedback = async (showFeedback: boolean) => {
  state.set((currentState) => ({
    ...currentState,
    showFeedback: showFeedback,
  }));
};

export const sendReview = async (isUseful: boolean) => {
  analytics.track('User Review', {
    is_useful: isUseful,
  });
};

export const sendRating = async (rating: number, suggestion: string) => {
  analytics.track('User Review', {
    rating: rating,
    suggestion: suggestion,
  });
};
