import { state } from '.';
import { tenantIdFromQuery } from '../common/consts';

const checkTenantIsEnabledQuery = `query CheckTenantIsEnabled($tenant_id: uuid!) @cached {
  tenant_by_pk(id: $tenant_id) {
    is_enabled
    enable_similar_in_measure
  }
}`;

export const checkTenantIsEnabled = async () => {
  const rawResponse = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT!, {
    method: 'POST',
    body: JSON.stringify({
      query: checkTenantIsEnabledQuery,
      variables: {
        tenant_id: tenantIdFromQuery,
      },
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const parsedResponse = await rawResponse.json();
  const tenantIsEnabled = parsedResponse.data?.tenant_by_pk?.is_enabled;
  const enableSimilarInMeasure =
    parsedResponse.data?.tenant_by_pk?.enable_similar_in_measure;

  state.set((currentState) => ({
    ...currentState,
    tenantIsEnabled: tenantIsEnabled,
    enableSimilarInMeasure: enableSimilarInMeasure,
  }));
};
