import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { analytics } from './common/analytics';
import { setCustomerData } from './common/consts';
import './common/misc';
import { initHeartBeat } from './heart-beat';
import './index.css';
import { loadInitialImage } from './preload-image';
import reportWebVitals from './reportWebVitals';
import { loadFeatureFlags } from './state/feature-actions';
import { loadLocalStorageItems } from './state/local-storage-actions';
import { checkTenantIsEnabled } from './state/initial-actions';

window.addEventListener('message', (e) => {
  if (e.data?.message === 'custom-data' && e.data?.data) {
    setCustomerData(e.data.data);
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

analytics.track('Widget Opened');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
initHeartBeat();
checkTenantIsEnabled();
loadInitialImage();
loadFeatureFlags();
loadLocalStorageItems();
