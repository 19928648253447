import { analytics } from '../analytics';

export const isIframe = window.self !== window.top;

export const closeIframeMessage = async () => {
  try {
    analytics.track('Close Iframe');
  } finally {
    window.parent.postMessage({ message: 'close' }, '*');
  }
};
