import { tenantIdFromQuery, isLocalhost } from './consts';

const getAnalytics = () => {
  return {
    track: (
      eventName: string,
      properties: Record<string, unknown> | undefined = {}
    ): void =>
      window.parent.postMessage(
        {
          message: 'track-event',
          data: {
            e_n: eventName.replace(/ /g, '_').toUpperCase(),
            o_e_n: eventName,
            t_id: tenantIdFromQuery,
            d: isLocalhost,
            ...properties,
          },
        },
        '*'
      ),
  };
};

export const analytics = getAnalytics();

export const trackProductClicked = (id: string, name: string) => {
  analytics.track('Product Clicked', {
    p_id: id,
    c_at: new Date().toISOString(),
  });
};
