// don't use this when trying to dynamically react to changes in the query

import { DepartmentType, OrderType, PriceType } from './enums';

// this is meant to be loaded just once during the initial load of the page
export const staticQuery: any = new Proxy(
  new URLSearchParams(window.location.search),
  {
    get: (searchParams, prop) => searchParams.get(prop as string),
  }
);

export const supportsMultiSearch = staticQuery.multisearch === 'true';
export const anonIdFromQuery = staticQuery.aId || '';
export const tenantIdFromQuery =
  staticQuery.tenantId || '45066e6e-892a-4504-a871-4fe0b5b33bb2';
export const initialImage = staticQuery.initialImage;
export const initialSize = staticQuery.size;
export const isLocalhost = window.location.hostname === 'localhost';

let customData = {
  genericErrorMessage: {
    title: 'Oops, algo deu errado',
    text: 'Estamos trabalhando para resolver o mais rápido possível, tente novamente mais tarde.',
  },
  pinSettings: {
    animation: 'animate-none',
    animationDuration: 1000,
  },
};

export const getCustomData = () => customData;

export const setCustomerData = (data: any) => {
  customData = { ...customData, ...data };
};

export type FilterType = DepartmentType | OrderType | PriceType | undefined;

export type Filter = { type: FilterType; name: string };
