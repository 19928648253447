import Compressor from 'compressorjs';
import { emptyState, getConvertedAtFromState, state } from '.';
import { analytics } from '../common/analytics';
import { tenantIdFromQuery } from '../common/consts';
import { AppStep, ErrorType } from '../common/enums';
import { loadFeatureFlags } from './feature-actions';
import { setShowFeedback } from './feedback-actions';
import {
  getStorageSearchesCounter,
  incrementSearchesCounter,
  loadLocalStorageItems,
} from './local-storage-actions';

const presignMutation = `mutation PresignTempUrl($fileType: String!) {
  presign_temp_asset_url(fileType: $fileType) {
    url
  }
}`;

const getProductSearchQuery = (enableCaching: boolean) => {
  return `
query ProductSearchV2($image_url: String!, $tenant_id: String!, $filters: ProductSearchFiltersV2 = null) ${enableCaching ? '@cached ' : ''}{
  product_search_v2(image_url: $image_url, tenant_id: $tenant_id, filters: $filters) {
    uri
    category
    category_score
    supercategory
    boxes {
      x
      y
    }
    results {
      id
      image_url
      cdn_fallback_image_url
      name
      price
      list_price
      discount_percentage
      product_page_url
      category
      supercategory
    }
  }
}
`;
};

const productSearchCropQuery = `query ProductSearchCrop($image_url: String!, $tenant_id: String!, $filters: ProductSearchFiltersV2 = null) {
  product_search_crop(image_url: $image_url, tenant_id: $tenant_id, filters: $filters) {
    uri
    category
    category_score
    supercategory
    boxes {
      x
      y
    }
    results {
      id
      image_url
      cdn_fallback_image_url
      name
      price
      list_price
      discount_percentage
      product_page_url
      category
      supercategory
    }
  }
}
`;

const acceptedImageTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
];
const twintyMB = 20 * 1024 * 1024;

export const searchImage = async (
  file?: File,
  cachedBaseUrlImage = '',
  opts?: {
    loadAsUrl: boolean;
    forceMimeType?: string;
    skipSearchCounter?: boolean;
    initialSize?: string;
  },
  enableCaching = false
) => {
  if (
    !acceptedImageTypes.includes(file?.['type'] || opts?.forceMimeType || '')
  ) {
    state.set((currentState) => ({
      ...currentState,
      error: { type: ErrorType.FileFormat },
    }));
    return;
  }

  if (file && file?.['size'] > twintyMB) {
    state.set((currentState) => ({
      ...currentState,
      error: { type: ErrorType.FileSize },
    }));
    return;
  }

  let resizedImage: File | undefined = undefined;

  state.set((currentState) => ({
    ...currentState,
    convertingImage: true,
  }));

  try {
    if (!opts?.loadAsUrl && file) {
      resizedImage = (await resizeImage(file)) as File;
    }
  } catch {
    state.set((currentState) => ({
      ...currentState,
      convertingImage: false,
      error: { type: ErrorType.GenericError },
    }));
    return;
  }

  state.set((currentState) => ({
    ...currentState,
    appStep: opts?.initialSize
      ? AppStep.SimilarInMeasurePage
      : AppStep.ResultsPage,
    initialSize: opts?.initialSize,
    searchingResults: true,
    searchingImage: true,
    convertingImage: false,
    results: [],
    currentImage: cachedBaseUrlImage,
    currentFileImage: resizedImage,
    error: {},
  }));

  if (!cachedBaseUrlImage && resizedImage) {
    // convert file to base64 image
    await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(resizedImage as File);
      reader.onload = () => {
        state.set((currentState) => ({
          ...currentState,
          currentImage: reader.result as string,
          error: {},
        }));
        resolve(true);
      };
      reader.onerror = (error) => {
        console.error(error);
        state.set((currentState) => ({
          ...currentState,
          error: { type: ErrorType.NetworkError },
        }));
      };
    });
  }

  state.set((currentState) => ({
    ...currentState,
    searchingResults: true,
    searchingImage: true,
  }));

  let imageUrl = opts?.loadAsUrl ? cachedBaseUrlImage : '';

  try {
    if (!opts?.loadAsUrl)
      imageUrl = await uploadTempImage(resizedImage, opts?.forceMimeType);

    state.set((currentState) => ({
      ...currentState,
      currentImage: imageUrl,
      isImageFromCatalog: opts?.loadAsUrl,
    }));

    const filters = getFilters();

    const results = await productsSearch(
      getProductSearchQuery(enableCaching),
      imageUrl,
      filters
    );

    if (results?.errors?.length) {
      state.set((currentState) => ({
        ...currentState,
        searchingResults: false,
        error: { type: ErrorType.GenericError, messages: results?.errors },
      }));
      return;
    }

    const parsedResults = parseResults(results?.data?.product_search_v2);

    state.set((currentState) => ({
      ...currentState,
      searchingResults: false,
      searchingImage: false,
      results: parsedResults,
      selectedResult: parsedResults[0],
    }));

    trackResults(parsedResults);

    if (!opts?.skipSearchCounter) {
      incrementSearchesCounter();
      let searches = getStorageSearchesCounter()!;
      if (searches === 3) setTimeout(() => setShowFeedback(true), 5000);
    }
  } catch (error) {
    console.error(error);
    state.set((currentState) => ({
      ...currentState,
      convertingImage: false,
      searching: false,
      error: { type: ErrorType.NetworkError },
    }));
    return;
  }
};

export const searchCroppedImage = async (
  file?: File,
  forceMimeType?: string
) => {
  if (!acceptedImageTypes.includes(file?.['type'] || forceMimeType || '')) {
    state.set((currentState) => ({
      ...currentState,
      error: { type: ErrorType.FileFormat },
    }));
    return;
  }

  if (file && file?.['size'] > twintyMB) {
    state.set((currentState) => ({
      ...currentState,
      error: { type: ErrorType.FileSize },
    }));
    return;
  }

  state.set((currentState) => ({
    ...currentState,
    convertingImage: true,
  }));

  let resizedImage: File | undefined;

  try {
    if (file) resizedImage = (await resizeImage(file)) as File;
  } catch {
    state.set((currentState) => ({
      ...currentState,
      convertingImage: false,
      error: { type: ErrorType.GenericError },
    }));
    return;
  }

  state.set((currentState) => ({
    ...currentState,
    searchingResults: true,
  }));

  try {
    const imageUrl = await uploadTempImage(resizedImage, forceMimeType);

    const filters = getFilters();

    const results = await productsSearch(
      productSearchCropQuery,
      imageUrl,
      filters
    );

    if (results?.errors?.length) {
      state.set((currentState) => ({
        ...currentState,
        searchingResults: false,
        error: { type: ErrorType.GenericError, messages: results?.errors },
      }));
      return;
    }

    const parsedResults = parseResults(results?.data?.product_search_crop);

    state.set((currentState) => ({
      ...currentState,
      searchingResults: false,
      results: parsedResults,
      selectedResult: parsedResults[0],
    }));

    trackResults(parsedResults);
  } catch (error) {
    console.error(error);
    state.set((currentState) => ({
      ...currentState,
      searching: false,
      error: { type: ErrorType.NetworkError },
    }));
    return;
  }
};

const resizeImage = (file: File): Promise<File | Blob> => {
  return new Promise(
    (res, rej) =>
      new Compressor(file, {
        quality: ['image/jpeg', 'image/webp'].includes(file.type)
          ? 0.8
          : undefined,
        maxWidth: 1024,
        maxHeight: 1024,
        mimeType: 'image/jpeg',
        success: (result) => res(result),
        error(error) {
          console.error(error);
          rej(error);
        },
      })
  );
};

const uploadTempImage = async (resizedImage?: any, forceMimeType?: string) => {
  let presignResponse: Response;

  presignResponse = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT!, {
    method: 'POST',
    body: JSON.stringify({
      query: presignMutation,
      variables: {
        fileType: resizedImage?.type || forceMimeType || '',
      },
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const {
    data: { presign_temp_asset_url },
  } = await presignResponse.json();

  // upload file to GCP
  const uploadResponse = await fetch(presign_temp_asset_url.url, {
    method: 'PUT',
    body: resizedImage,
    headers: {
      'Content-Type': resizedImage?.type || forceMimeType || '',
      'x-goog-content-length-range': '10,10240000',
    },
  });

  const url = new URL(uploadResponse.url);
  return `${url.origin}${url.pathname}`;
};

const getFilters = () => {
  let filters = {};
  if (state.get().selectedDepartment)
    filters = { department: state.get().selectedDepartment.type };
  if (state.get().selectedPriceTiers?.length) {
    filters = {
      ...filters,
      price_tier: state.get().selectedPriceTiers?.map((tier) => tier.type),
    };
  }
  if (state.get().initialSize)
    filters = { ...filters, size: state.get().initialSize };
  return filters;
};

const productsSearch = async (
  query: string,
  imageUrl: string,
  filters?: {}
) => {
  const rawResponse = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT!, {
    method: 'POST',
    body: JSON.stringify({
      query: query,
      variables: {
        tenant_id: tenantIdFromQuery,
        image_url: imageUrl,
        filters,
      },
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  analytics.track('Product Searched', {
    c_at_s: getConvertedAtFromState(),
  });

  return await rawResponse.json();
};

const parseResults = (results: any) => {
  const parsedResults =
    results?.map((type: Record<string, unknown>, index: number) => ({
      ...type,
      index,
    })) || [];

  parsedResults.forEach((parsedResult: any) => {
    parsedResult.results = parsedResult.results.map(
      (type: Record<string, unknown>, index: number) => ({
        ...type,
        index,
      })
    );
  });

  return parsedResults;
};

const trackResults = (parsedResults: any) => {
  const noneFound = !parsedResults[0]?.results?.length;
  const boxesNotFound = !parsedResults[0]?.boxes.length;
  const productIdsResult =
    parsedResults?.map(
      (parsedResult: any) =>
        parsedResult.results?.map((product: any) => product.id) || []
    ) || [];

  if (noneFound) {
    analytics.track('Result Rendered', {
      c_at_s: getConvertedAtFromState(),
      state: 'No Results',
      results: productIdsResult,
    });
  } else if (boxesNotFound) {
    // same supercategory, but not similar
    analytics.track('Result Rendered', {
      c_at_s: getConvertedAtFromState(),
      state: 'By Supercategory',
      results: productIdsResult,
    });
  } else {
    analytics.track('Result Rendered', {
      c_at_s: getConvertedAtFromState(),
      state: 'Similar',
      results: productIdsResult,
    });
  }
};

export const setCurrentResult = (index: number) => {
  state.set((currentState) => {
    analytics.track('Pin Selected', {
      convertedAt: getConvertedAtFromState(),
      category: currentState.results[index]?.category || '',
      supercategory: currentState.results[index]?.supercategory || '',
      category_score: currentState.results[index]?.category_score || 0,
    });

    return {
      ...currentState,
      selectedResult: currentState.results[index],
    };
  });
};

export const convertDataUrlToFile = async (url: string) => {
  return await fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      return new File([blob], '', { type: 'image/jpeg' });
    });
};

export const restartApp = () => {
  state.set(emptyState);
  loadFeatureFlags();
  loadLocalStorageItems();
};
